import React, {useState, useEffect} from "react"
import styled from "styled-components"
import Config from '../config'

import Container from "../components/container"
import Header from "../components/header"
import Navbar from "../components/navbar"
import SEO from "../components/seo"
import Footer from "../components/footer"

const Row = styled.div`
  margin-top: 72px;
`

const TwoColumnTemplate = ({ pageContext }) => {
  const { node } = pageContext
  const [data, setData] = useState({})

  const getData = async stringID => {
    const id = stringID.match(/(\d+)/)[0]
    const call = await fetch(
      `${Config.apiUrl}/items/pages/${id}?access_token=${Config.token}&fields=*.*.*`
    )
    const response = await call.json()
    setData(response.data)
  }

  useEffect(() => {
    getData(node.id)
  }, [node])

  return (
    <>
      <SEO
        title={node.meta_title}
        desc={node.meta_desc}
        keywords={node.meta_keywords}
        ogTitle={node.og_title}
        ogDesc={node.og_desc}
        ogImage={node.og_image?.data.full_url}
      />

      <Container>
        <Navbar />

        {data.banner_display && (
          <Header
            image={data.banner?.data.full_url}
            title={data.title}
            subtitle={data.subtitle}
          />
        )}

        <main id="main">
          <section id="about" className="about">
            <div className="container">
              <div className="section-title">
                <h2>{data.head}</h2>
              </div>
            </div>
            <div className="container">
              {data.paragraphe && Object.keys(data.paragraphe).length &&
                data.paragraphe.map((item, i) => {
                  return i % 2 === 0 ? (
                    <div className="row" key={i}>
                      <div className="col-lg-6 order-1 order-lg-2">
                        <div className="about-img">
                          <img src={item.image.data.full_url} alt="" />
                        </div>
                      </div>
                      <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.content,
                          }}
                        ></div>
                      </div>
                    </div>
                  ) : (
                    <Row className="row" key={i}>
                      <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-2 content">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.content,
                          }}
                        ></div>
                      </div>
                      <div className="col-lg-6 order-1 order-lg-1">
                        <div className="about-img">
                          <img src={item.image?.data.full_url} alt="" />
                        </div>
                      </div>
                    </Row>
                  )
                })}
            </div>
          </section>
        </main>

        <Footer />
      </Container>
    </>
  )
}

export default TwoColumnTemplate
